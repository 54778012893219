import {  Outlet, } from "react-router-dom";

import { NavigationBar } from "../../components";

import styles from './MainPage.module.css'

interface IMainPage {
    resetCameraSteps: () => void;
}


const MainPage = ({resetCameraSteps}: IMainPage) => {
    return (
        <div className={styles.mainContainer}>
            <NavigationBar resetCameraSteps={resetCameraSteps}/>
            <Outlet />
        </div>
    )
}

export default MainPage;