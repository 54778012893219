export const en = {
    welcomePage: {
        csrTitle: "CaeSaR",
        title: "Authenticator",
        subtitle: "Say goodbye to counterfeiting Products",
        getStarted: "Get Started"
    },
    homePage: {
        welcomeTo: "CaeSaR Authenticator",
        authenticator: "Say goodbye to counterfeit products",
        generateCsrId: "Enrollment",
        NewEnrollment: "New Enrollment",
        validateCsrId: "Authenticate"
    },
    faqPage: {
        faqTitle: "What is CSRs",
        faqText: "ChLCs in spherical shape are called Cholesteric Spherical Reflectors (CSRs)"
    },
    signinPage: {
        traceCrystalLogo: "Trace Crystal Logo",
        signIn: "Sign In"
    },
    signupPage: {
        traceCrystalLogo: "Trace Crystal Logo",
        signUp: "Sign Up",
        success: "User successfully registered.",
        noUsername: "To proceed with registration, please insert username",
        privacyPolicyError: "To proceed with registration, please accept our Privacy Policy.",
        privacyPolicy1: "Yes, I have taken note of",
        privacyPolicy2: " Trace Crystal's privacy policy ",
        privacyPolicy3: "and consent that the information provided by me is collected and stored electronically. "
    },
    vendorPage: {
        traceCrystalLogo: "Trace Crystal Logo",
        signIn: "Sign In",
        signUp: "Sign Up",
        logout: "Logout",
        titleText: "Are you a Vendor?",
        alreadyRegistered: "You are already registered?",
        notRegistered: "Not registered yet?",
        name: "Name",
        email: "Email",
        enrollment: "Enrollment",
        products: "Products",
        na: "N/A",
        insertMetaData: {
            title: "Insert Product Data",
            productName: "Product Name *",
            description: "Description",
            productionCountry: "Product Country",
            productionCity: "Product City",
            productCode: "Product Code",
            submit: "Submit"
        },
        resultStep: {
            enrolledProduct: "Enrolled Product",
            productName: "Product Name:",
            productDescription: "Product Description:",
            productCode: "Product Code:",
            productLocation: "Product Location:",
            csrId: "CaeSaR ID:",
        }
    },
    cameraPage: {
        discard: "Discard",
        confirm: "Confirm",
        youMustInsertCsrId: "You must insert a CaeSaR ID first",
        errorPostingImage: "Error posting image",
        errorValidatingImage: "Error validating image"
    },
    VendorCameraPage: {
        gallery: "Gallery",
        myImages: "My Products",
        enrollment: "Enrollment",
        insertMetaData: "Insert Metadata",
        result: "Result",
    },
    UserCameraPage: {
        gallery: "Gallery",
        authenticate: "Authenticate",
        takePhoto: "Take Photo",
        insert: "Insert Metadata",
        resultAuthenticate: "Authenticate Result",
        csridPlaceholder: "CaeSaR ID *",
        cityPlaceholder: "City *",
        townPlaceholder: "Country *",
        na: " N/A",
        validateStep: {
            validationFailed: "Validation Failed",
            validationPassed: "Validation Passed",
            productInformation: "Product Information",
            retry: "Retry",
            name: "Name:",
            description: "Description:",
            location: "Location:",
            productCode: "Product Code:"
        }
    },
    components:{
        emailInput: {
            placeholder: "Email"
        },
        genericInput: {
            placeholder: "Username"
        },
        passwordInput: {
            placeholder: "********",
        },
        navigationBar: {
            homePlaceholder: "Home",
            cameraPlaceholder: "Camera",
            faqPlaceholder: "FAQ",
            vendorPlaceholder: "Vendor",
            homeText: "Home",
            cameraText: "Authenticate",
            vendorCameraText: "Enrollment",
            faqText: "FAQ",
            vendorText: "Sign In",
            vendorLoggedText: "Profile"
        }
    },
    steps: {
        stepOne: "1",
        stepTwo: "2",
        stepThree: "3"
    }
}