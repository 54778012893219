import {  string } from "yup";

export const passwordSchema = string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]+/, "Password must contain at least one lowercase character")
    .matches(/[A-Z]+/, "Password must contain at least one uppercase character")
    .matches(/[@$!%*#?&]+/, "Password must contain at least one special character")
    .matches(/\d+/, "Password must contain at least one number");

  