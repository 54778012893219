import Overlay from '../Overlay/Overlay';
import { IResponseProduct } from '../../constants/IProducts';
import {  useGetProductsImagesQuery } from '../../store/api/productsApi';
import TimeLineModal from '../TimeLineModal';

import styles from './MyProductsModal.module.css';
import { useCallback, useEffect } from 'react';

interface IProductDetailsModalProps {
    onClose: () => void;
    selectedProduct: IResponseProduct;
}

const ProductDetailsModal = ({ onClose, selectedProduct }: IProductDetailsModalProps) => {
    const { data, isLoading } = useGetProductsImagesQuery(selectedProduct.id);

    const closeByEsc = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') 
            onClose();
    }, [onClose]);

    useEffect(() => {
        document.addEventListener('keydown', closeByEsc);
        return () => {
            document.removeEventListener('keydown', closeByEsc);
        };
    }, [closeByEsc]);

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={onClose}>X</button>
                <div className={styles.productDetails}>
                    <div className={styles.detailsImageContainer}>
                        {!isLoading &&  data && data[0]?.image_blob.length > 0  && <img src={`data:image/png;base64, ${ data[0]?.image_blob}`} alt='' className={styles.detailsCsrImage} />}
                      
                    </div>
                    <div className={styles.textDetailsContainer}>
                        <p className={styles.span}><strong>Name:</strong> {selectedProduct.name}</p>
                        <p className={styles.span}><strong>CaeSaR ID:</strong> {selectedProduct.csr_code}</p>
                        <p className={styles.span}><strong>Description:</strong> {selectedProduct.description}</p>
                        <p className={styles.span}><strong>Product Code:</strong> {selectedProduct.product_code}</p>
                        <p className={styles.span}><strong>Production Country:</strong> {selectedProduct.production_country}</p>
                        <p className={styles.span}><strong>Production City:</strong> {selectedProduct.production_city}</p>
                        <p className={styles.span}><strong>Data Creation:</strong> {selectedProduct.data_creation.split('.')[0]}</p>
                        {selectedProduct.data_update ? <p className={styles.span}><strong>Data Update:</strong> {selectedProduct.data_update}</p> : null}
                        <p className={styles.span}><strong>Validations Counter:</strong> {selectedProduct.validations_counter}</p>
                        <TimeLineModal content={selectedProduct.infos} validation={selectedProduct.validations_counter} />
                    </div>
                </div>
            </div>
            {isLoading && <Overlay />}
           </div>
    );
};

export default ProductDetailsModal;