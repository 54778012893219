import { useState } from 'react';
import styles from './TimeLineModal.module.css';
import arrowIcon from '../../assets/icons/arrow_right.svg';
import { IInfoResponse } from '../../constants/IProducts';

interface ITimeLineModal {
    content: IInfoResponse[];
    validation: number;
}

const TimeLineModal = ({content, validation}: ITimeLineModal) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    const sortedContent = [...content].sort((a, b) => new Date(b.data_creation).getTime() - new Date(a.data_creation).getTime());
    
    return (
        <div className={styles.accordionItem}>
            {validation > 0 ? (
                <div className={styles.accordionTitle} onClick={() => setIsActive(!isActive)}>
                    <div><strong>Validation Timeline</strong></div>
                    <img className={styles.arrowIcon} src={arrowIcon} alt=''/>
                </div>
            ): null }
            {isActive &&
                <div className={styles.accordionContent}>
                    <button className={styles.close} onClick={()=> setIsActive(false)}>X</button>
                    <div className={styles.infoContainer}>
                        <p className={styles.validationTitle}>Validation Timeline</p>
                        {sortedContent.map((item: IInfoResponse, index: any) => (
                            <div className={styles.validationInfos} key={index}>
                                {!item.data_update ? <div><strong>data scan:</strong> {item.data_creation.split('.')[0]}</div> : null}
                                <div><strong>Country:</strong> {item.actual_country}</div>
                                <div><strong>City:</strong> {item.actual_city}</div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div> 
    )
};

export default TimeLineModal;