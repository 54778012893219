export interface IProduct {
    name?: string;
    production_country?: string;
    product_code?: string;
    production_city?: string;
    description?: string;
    csr_image: string;
}

export interface IInfoResponse {
    id: 0;
    data_creation: string;
    data_update: string;
    actual_country: string;
    actual_city: string;
    product_id: number;

}
export interface IResponseProduct { 
    id: number;
    data_creation: string;
    data_update: string;
    name: string;
    description: string;
    product_code: string;
    production_country: string;
    production_city: string;
    csr_code: number;
    csr_image: string; 
    validations_counter: number;
    infos: [IInfoResponse];
}

export type IResponseProducts = IResponseProduct[];
export interface IProductInfo { 
    id: 0;
    data_creation: string;
    data_update: string;
    name: string;
    description: string;
    product_code: string;
    production_country: string;
    production_city: string;
    csr_code: 0;
    csr_image: string; 
}
export interface IValidationRequest {
    csr_code: number;
    csr_image: string;
    city: string;
    country: string;
}
export interface IValidationResponse {
    status: string;
    product: IResponseProduct;
}
export interface IProductImage {
    base64_image: string;
    is_main: boolean;
  }
  export type IProductImages = IProductImage[];
  export interface IProductImageRequest {
    id: number;
    productImages: IProductImages;
}
export interface IProductImageResponse {
    id: number,
    data_creation: string,
    data_udpate: string,
    image_blob: string,
    product_id: number,
    is_main: boolean
}

export type IProductImagesResponse = IProductImageResponse[]
;
export interface ListRequest {
    take: number;
    page : number;
    txt?: string;
}

export interface ListResponse<T> {
    page: number
    per_page: number
    total: number
    total_pages: number
    data: T[]
  }

export const initialProduct : IProduct = {
    name: '',
    production_country: '',
    product_code: '',
    production_city: '',
    description: '',
    csr_image: ''
}
export const initialInfoResponse : IInfoResponse = {
    id: 0,
    data_creation: "",
    data_update:  "",
    actual_country:  "",
    actual_city:  "",
    product_id: 0
}
export const initialResponseProduct : IResponseProduct = {
    name: '',
    production_country: '',
    product_code: '',
    production_city: '',
    description: '',
    csr_image: '',
    id: 0, 
    data_creation:'', 
    data_update: '', 
    csr_code: 0,
    validations_counter: 0,
    infos: [initialInfoResponse]
}

export const initialRequest: ListRequest = {
    take: 10,
    page : 1,
    txt : ''
}

export const initialResponseProducts : ListResponse<IResponseProduct> = {
    page: 0,
    per_page: 0,
    total: 0,
    total_pages: 0,
    data: [ initialResponseProduct]
}

export const initialCsr : IValidationRequest = {
    csr_code: 0,
    csr_image: '',
    city: "",
    country: ""
}

export const initialProductImages : IProductImages = [
    {
        base64_image: '',
        is_main: false 
    }
]

export const initialValidatedImage : IValidationResponse = {
    status: 'fail',
    product:  initialResponseProduct
}


