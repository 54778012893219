import { useRef, useState, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { Popover } from 'react-tiny-popover'
import Cropper, { Area, Point } from "react-easy-crop";


import getCroppedImg from "./cropImageUtility";

import rightArrow from '../../../assets/icons/arrow_right.svg'
import cameraIcon from '../../../assets/icons/camera1.svg'
import { IProduct,  initialProduct } from "../../../constants/IProducts";

import styles from './CameraStep.module.css'
import Overlay from "../../Overlay/Overlay";

interface IInputDevice {
  deviceId: string;
  groupId: string;
  kind: string;
  label:string;
}

interface ICamerStep {
  confirmedImage: (image: IProduct) => Promise<void>;
  titleStep: string;
}

const initialInputDevice = {
  deviceId: "",
  groupId: "",
  kind: "",
  label: ""
}

const initialInputDevices = [initialInputDevice];

const CameraStep = ({confirmedImage, titleStep}:ICamerStep) => {
    const { t } = useTranslation();
    const webcamRef = useRef<Webcam>(null);
    const [image, setImage] = useState<IProduct>(initialProduct);
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const [devices, setDevices] = useState<IInputDevice[]>(initialInputDevices);
    const [selectedDevice, setSelectedDevice] = useState<IInputDevice>(initialInputDevice);
    const [deviceCapabilities, setDeviceCapabilities] = useState<MediaTrackCapabilities>({});
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({width: 0, height: 0, x: 0, y: 0})
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCameraInitialized, setIsCameraInitialized] = useState<boolean>(false);

    const onCropComplete = (AreaPixels: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels)
    };

    const showCroppedImage = useCallback(async () => {
      try {
        if (!croppedAreaPixels.width || !croppedAreaPixels.height) {
          throw new Error("Cropped area is invalid.");
        }
        const resize = { width: 800, height: 800 }; 
        const croppedImage = await getCroppedImg(image.csr_image, croppedAreaPixels, 0, { horizontal: false, vertical: false }, resize);
        if (croppedImage) {
          return croppedImage;
        }
      } catch (e) {
        console.error(e);
      }
      return null;
    }, [croppedAreaPixels, image.csr_image]);

    const discardImage = useCallback(() => {
      setZoom(1);
      setImage(initialProduct);
    },[])

  async function getVideoCapabilities(deviceId: string) {
    try {
      const constraints = { video: { deviceId: { exact: deviceId } } };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      const track = stream.getVideoTracks()[0];
      const capabilities = track.getCapabilities();
      setDeviceCapabilities(capabilities); 

      track.stop();
    } catch (err) {
      console.error('Error accessing media devices.', err);
    }
  }

  const handleDevices = useCallback((mediaDevices: MediaDeviceInfo[]) => {
    const videoDevices = mediaDevices.filter(({ kind }) => kind === "videoinput");
    setDevices(videoDevices);
    console.log(videoDevices)
    if(videoDevices[0].deviceId === "") {
        toast.warning('No Camera Detected')
        setSelectedDevice(initialInputDevice)
    }

    if(videoDevices[0] && selectedDevice.deviceId === "")  
        setSelectedDevice(videoDevices[0])

    if (selectedDevice.deviceId !== "" && 
        videoDevices.length !== 0 && 
        !videoDevices.find(device => device.deviceId === selectedDevice.deviceId)) 
    {
        toast.warning('Selected camera not found');
        setSelectedDevice(videoDevices[0])
    }

    if (selectedDevice.deviceId !== "") {
        getVideoCapabilities(selectedDevice.deviceId);
    }
  }, [selectedDevice.deviceId]);

  const handleInitialization = useCallback ((event:MediaStream) => {
    setIsCameraInitialized(false);
    if(event.active) {
      setTimeout(async () => {
        setIsCameraInitialized(true);
      }, 500)
    } 
  },[])

    useEffect(() => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },[handleDevices ]);

  
    const capture = useCallback(async () => { 
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();

        if(imageSrc){
          try {
            const image: IProduct = { csr_image: imageSrc.split(',')[1] };
            setImage(image);
          } catch(error) {
            toast.error(t('cameraPage.errorPostingImage'));
            throw new Error(t('cameraPage.errorPostingImage'));
          }
          setIsLoading(false);
        } 
      }
    },[t, webcamRef]);

    const handleCapture = useCallback(() => {
      setIsLoading(true);
      setTimeout(async () => {
        await capture();
      }, 1000); 
    }, [capture]);

    const confirmImage = useCallback(async () => {
      const croppedImage = await showCroppedImage();
      if (croppedImage) {
        const csrCroppedImage: IProduct = { csr_image: croppedImage.split(',')[1] };
        setImage(csrCroppedImage);
        await confirmedImage(csrCroppedImage);
        discardImage();
      } else {
        toast.warning('Image cropping gone wrong.');
      }
    }, [confirmedImage, discardImage, showCroppedImage]);

    const handlePopover = useCallback(() => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
      setIsPopoverOpen(!isPopoverOpen)
    },[handleDevices, isPopoverOpen])

    const handleDeviceSelect = useCallback((device: any) => {
      setSelectedDevice(device);
      setIsPopoverOpen(false); 
      setIsCameraInitialized(false);
    }, []);

    const renderDevices = useCallback(() => {
      if(devices.length > 0) {
        return (
          <div className={styles.deviceList}>
            {devices.map(device => (
              <button key={device.deviceId} className={styles.selectDeviceButton} onClick={() => handleDeviceSelect(device)}>
                {device.label || `Device ${devices.indexOf(device) + 1}`}
              </button>
              
            ))}
          </div>)
      } else {
        return <div className={styles.deviceList}><span className={styles.noDevices}>No Devices</span></div>
      }
    }, [devices, handleDeviceSelect]);

    
    

    const videoConstraints = selectedDevice && deviceCapabilities ? {
      deviceId: selectedDevice.deviceId,
      frameRate: { 
        min: deviceCapabilities.frameRate?.min, 
        max: deviceCapabilities.frameRate?.max, 
        ideal: deviceCapabilities.frameRate?.max,
      },
      width: { 
        min: deviceCapabilities.frameRate?.min, 
        max: deviceCapabilities.width?.max, 
        ideal: deviceCapabilities.width?.max,
      },
      height: { 
        min: deviceCapabilities.frameRate?.min , 
        max: deviceCapabilities.height?.max, 
        ideal: deviceCapabilities.height?.max,
      },
      resizeMode: "none"
    } : {};
     
    return (
      <>
        <h2 className={styles.titleStep}>{titleStep}</h2>
        <div className={styles.something}>
          <Popover
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            positions={['bottom', 'right']}
            content={renderDevices()}
          >
            <button className={styles.selectCamera} onClick={handlePopover}>
              {selectedDevice.label || 'Select Camera'}
            <img className={styles.popoverRightArrow} src={rightArrow} alt='Select Camera' style={{ transform: isPopoverOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}/>
            </button>
          </Popover>
        </div>
        <div className={styles.glass}>
          <div className={styles.webcamContainer}>
            {image.csr_image 
              ? 
              <div className={styles.cropperContainer}>
                 <Cropper
                  image={`data:image/jpeg;base64,${image.csr_image}`} 
                  crop={crop}
                  zoom={zoom}
                  maxZoom={1}
                  minZoom={1}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              :    <Webcam  videoConstraints={videoConstraints} forceScreenshotSourceSize screenshotQuality={1} ref={webcamRef}  className={styles.webcam} screenshotFormat="image/webp" onUserMedia={handleInitialization}/>
            }
          </div>
          {!image.csr_image ?
            <div className={styles.floatingButtonContainer}>
              {!isLoading && isCameraInitialized && <button className={styles.shotCameraButton} onClick={handleCapture}>
                <img src={cameraIcon} alt="Camera" className={styles.icon} />
              </button>}
            </div>
            : 
            <div className={styles.imageControls}>
              <button className={styles.discardButton} onClick={discardImage}>{t('cameraPage.discard')}</button>
              <button className={styles.confirmButton} onClick={confirmImage}>{t('cameraPage.confirm')}</button>
            </div>
          }
            {isLoading && <Overlay/>}
        </div>
      </>
    )
}

export default CameraStep;
