import { ChangeEvent } from 'react';

import searchIcon from '../../assets/icons/search-svgrepo-com.svg'
import styles from './Searchbar.module.css';

interface ISearchbar {
    onSearchHandler: (query: ChangeEvent<HTMLInputElement>) => void;
}


const Searchbar = ({onSearchHandler}: ISearchbar) => {
    return (
        <div className={styles.userBox}>
            <img className={styles.icon} src={searchIcon} alt='search icon'/>
            <input
                className={styles.searchInput}
                id={styles.genericInput}
                onChange={onSearchHandler}
                autoComplete="off"
            />
        </div>
    )
}

export default Searchbar;