import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UrlEnum } from '../../constants/url';
import { IToken, IUser, loginUserType } from "../../constants/IUser";

const baseQuery = fetchBaseQuery({
    baseUrl: window._env_.BE_API_URL,
    prepareHeaders: (headers) => {
        const token = sessionStorage.getItem('access_token');

        if (token) headers.set('Authorization', `Bearer ${token}`);
        return headers;
    },
});

export const userDataApi = createApi({
    reducerPath: 'userDataApi', 
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        addUser: builder.mutation<void, IUser>({
            query: (user) => ({
                url: UrlEnum.USER_URL + UrlEnum.REGISTER_URL,
                method: 'POST',
                body: user,
            }),
        }),
        loginUser: builder.mutation<IToken, loginUserType>({
            query: (user) => ({
                url: UrlEnum.USER_URL + UrlEnum.LOGIN_URL,
                method: 'POST',
                body: user,
            }),
        }),
        logoutUser: builder.mutation<string, string>({
            query: (refreshToken) => ({
                url: `${UrlEnum.USER_URL}${UrlEnum.LOGOUT_URL}?refresh_token=${refreshToken}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { useAddUserMutation, useLoginUserMutation, useLogoutUserMutation } = userDataApi;
