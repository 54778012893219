import { useCallback, useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';

import { IProduct, IProductImages, initialProduct } from '../../../constants/IProducts';

import trashIcon from '../../../assets/icons/trash-svgrepo-com.svg'
import styles from './InsertMetaDataStep.module.css';
import { useTranslation } from 'react-i18next';

interface IInsertMetaDataStep {
    setMetaData: (data: IProduct) => Promise<void>;
    setImagesMetaData: (images: IProductImages) => Promise<void>
}

const InsertMetaDataStep = ({ setMetaData, setImagesMetaData }: IInsertMetaDataStep) => {
    const [isMouseOnImage, setIsMouseOnImage] = useState<number>(-1);
    const [formData, setFormData] = useState<IProduct>(initialProduct);
    const [images, setImages] = useState<File[]>([]);
    const [imageURLS, setImageURLS] = useState<string[]>([]);
    const [productImages, setProductImages] = useState<IProductImages>([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const newImageUrls: string[] = images.map(image => URL.createObjectURL(image));
        setImageURLS(newImageUrls);
      }, [images]);

    const isNonEmptyString = (str?: string) => (str && str.trim().length > 0);

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    },[])

    const handleDeleteImage = useCallback((index: number) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    
        const updatedURLS = [...imageURLS];
        updatedURLS.splice(index, 1);
        setImageURLS(updatedURLS);
    
        const updatedProductImages = [...productImages];
        updatedProductImages.splice(index, 1);
        setProductImages(updatedProductImages);
    },[imageURLS, images, productImages])

    const onImageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return btoa(binary);
        };

        const _handleReaderLoaded = (readerEvt:any, selectedFiles: File[]) => {
            const updatedImages: IProductImages = selectedFiles.map(() => {
                const binaryString = readerEvt.target.result;
                const base64Image = arrayBufferToBase64(binaryString);

                return { base64_image: base64Image, is_main: false };
            });
        
            const newProductImages = [...productImages, ...updatedImages];
            setProductImages(newProductImages);
        };
        
        if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            if (selectedFiles.length > 0) {
                if (images.length < 3) {
                    const reader = new FileReader();
                    reader.onload = (readerEvt) => _handleReaderLoaded(readerEvt, selectedFiles);
                    reader.readAsArrayBuffer(selectedFiles[0]);
                    
                    const newImages = [...images, ...selectedFiles];
                    
                    if (images.length === 3) {
                        const updatedImages = [...images];
                        updatedImages.pop(); 
                        setImages([...updatedImages, selectedFiles[0]]); 
                    } else {
                        setImages(newImages); 
                    }
                } else {
                    toast.warning('You can upload just one image');
                }
            }
        }
    }, [images, productImages]);
    
    const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement> | KeyboardEvent) => {
        event.preventDefault(); 
        event.stopPropagation();
    
        if (isSubmitting) {
            return;
        }
    
        setIsSubmitting(true);
    
        const updatedProductImages = productImages.map((image, index) => ({
            ...image,
            is_main: false,
        }));
        await setMetaData(formData);
        await setImagesMetaData(updatedProductImages);
    
        setIsSubmitting(false);
    },[formData, productImages, setImagesMetaData, setMetaData, isSubmitting]);

    const isNameFieldOk = useMemo(() => {
        return isNonEmptyString(formData.name);
    }, [formData.name]);

    const nextStep = useCallback((event: KeyboardEvent) => {
        if ( isNameFieldOk && event.key === 'Enter') 
            handleSubmit(event);
    }, [handleSubmit, isNameFieldOk]);

    useEffect(() => {
        document.addEventListener('keydown', nextStep);
        return () => {
            document.removeEventListener('keydown', nextStep);
        };
    }, [nextStep]);

    return (
        <div className={styles.glass}>
            <h2 className={styles.title}>{t('vendorPage.insertMetaData.title')}</h2>
            <form onSubmit={handleSubmit} className={styles.form} id="InsertMetaDataForm" rel="noreferrer">
                <div className={styles.userBox}>
                    <input
                        className={styles.metaInput}
                        name="name"
                        type="text"
                        value={formData.name}
                        onChange={handleInputChange}
                        autoComplete='off'
                    />
                    <label className={formData.name === "" ? styles.label : `${styles.label} ${styles.hLabel} `}>{t('vendorPage.insertMetaData.productName')}</label>
                </div>
                <div className={styles.userBox}>
                    <input
                        name="description"
                        type="text"
                        value={formData.description} 
                        onChange={handleInputChange}
                        autoComplete='off'
                    />
                     <label className={formData.description === "" ? styles.label : `${styles.label} ${styles.hLabel} `}>{t('vendorPage.insertMetaData.description')}</label>
                </div>
                <div className={styles.userBox}>
                    <input
                        name="production_country"
                        type="text"
                        value={formData.production_country}
                        onChange={handleInputChange}
                        autoComplete='off'
                    />
                    <label className={formData.production_country === "" ? styles.label : `${styles.label} ${styles.hLabel} `}>{t('vendorPage.insertMetaData.productionCountry')}</label>
                </div>
                <div className={styles.userBox}>
                    <input
                        name="production_city"
                        type="text"
                        value={formData.production_city}
                        onChange={handleInputChange}
                        autoComplete='off'
                    />
                     <label className={formData.production_city === "" ? styles.label : `${styles.label} ${styles.hLabel} `}>{t('vendorPage.insertMetaData.productionCity')}</label>
                </div>
                <div className={styles.userBox}>
                    <input
                        name="product_code"
                        type="text"
                        value={formData.product_code}
                        onChange={handleInputChange}
                        autoComplete='off'
                    />
                     <label className={formData.product_code === "" ? styles.label : `${styles.label} ${styles.hLabel} `}>{t('vendorPage.insertMetaData.productCode')}</label>
                </div>
                <input className={styles.updateImageInput} type="file" accept="image/*" onChange={onImageChange} />
                <div className={styles.imagePreviewContainer}>
                    {imageURLS.map((imageSrc, index) => (
                        <div key={crypto.randomUUID()} className={styles.imagePreviewButton} onMouseEnter={() => setIsMouseOnImage(index)} onMouseLeave={() => setIsMouseOnImage(-1)}>
                            <img src={imageSrc} alt="selected images" className={styles.imagesPreview} />
                            {isMouseOnImage === index && (
                                <span className={styles.deleteButton} onClick={() => handleDeleteImage(index)}>
                                <img className={styles.trashImageIcon} alt='trash icon' src={trashIcon} />
                                </span>
                            )}
                        </div>
                    ))}
                </div>

                <button className={isNameFieldOk ? styles.newButton : `${styles.newButton} ${styles.disabledButton}`} type="submit">{t('vendorPage.insertMetaData.submit')}</button>  
            </form>  
        </div>
    );
};

export default InsertMetaDataStep;

