import React from "react";
import { ILabel } from "./ILabel";

const Label : React.FC<ILabel>= ({className, id, htmlFor, form, labelText }) => {
  return (
    <label className={className} id={id}  htmlFor={htmlFor} form={form}>
        {labelText}
    </label>
  );
};

export default React.memo(Label);
