export interface ILocationRequest {
    latitude: number;
    longitude: number;
}

export interface ILocationResponse {
    city: string;
    country: string;
}
export const initialLocationRequest : ILocationRequest = {
    latitude: 0,
    longitude: 0
}

export const initialLocationResponse : ILocationResponse = {
    city: "",
    country: ""
}