import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Form, GenericInput, EmailInput, PasswordInput } from "../../components";
import useHandleSignup from "../../hooks/useHandleSignup";
import { IUser, IValidation, initialUserState, initialValidationState } from "../../constants/IUser";

import styles from './SignupPage.module.css';

interface ISignupPage {
  handleSignupLoading: (isLoading: boolean) => void;
  handleSignupPage?: () => void;
}

const SignupPage = ({ handleSignupLoading, handleSignupPage }: ISignupPage) => {
  const { t } = useTranslation();
  const { handleSignup, isLoading } = useHandleSignup();

  const [user, setUser] = useState<IUser>(initialUserState);
  const [validation, setValidation] = useState<IValidation>(initialValidationState);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isPrivacyChecked, setIsPrivacyChecked] = useState<boolean>(false);

  useEffect(() => {
    handleSignupLoading(isLoading);
  }, [handleSignupLoading, isLoading]);

  const isNonEmptyString = (str?: string) => (str && str.trim().length > 0);

  const handleSubmit = useCallback(async (name: string, email: string, password: string) => {
    let isSignupOk = false;

    if (!isPrivacyChecked) {
      toast.error(t('signupPage.privacyPolicyError'));
      return;
    }

    if (!isNonEmptyString(user.name)) {
      toast.error(t('signupPage.noUsername'));
      return;
    }

    if (user.password === confirmPassword) {
      isSignupOk = await handleSignup({ name, email, password });
      if (isSignupOk) {
        handleSignupPage?.();
        toast.success(t('signupPage.success'));
      }
    } else {
      toast.error(t('signupPage.passwordMismatch'));
    }
  }, [isPrivacyChecked, user.password, user.name, confirmPassword, t, handleSignup, handleSignupPage]);

  const handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setConfirmPassword(value);
  };

  const onSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleValidation = (fieldName: string, isValid: boolean) => {
    setValidation((prev) => ({
      ...prev,
      [fieldName]: isValid,
    }));
  };

  const handlePrivacyPolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivacyChecked(event.target.checked);
  };

  return (
    <Form className={styles.signupForm} id="SignupForm" rel="noreferrer" submitForm={handleSubmit} validation={Object.values(validation)} user={user} buttonText={t('signupPage.signUp')}>
      <GenericInput
        name='name'
        onChange={onSubmit}
        value={user.name}
        onValidationChange={handleValidation}
      />
      <EmailInput
        onChange={onSubmit}
        value={user.email}
        onValidationChange={handleValidation}
      />
      <PasswordInput
        onChange={onSubmit}
        value={user.password}
        onValidationChange={handleValidation}
        showValidationLabel={true}
        labelValue="Password"
      />
      <PasswordInput
        onChange={handleConfirmPassword}
        value={confirmPassword}
        onValidationChange={handleValidation}
        showValidationLabel={false}
        labelValue="Confirm Password"
      />
      <div className={styles.privacyPolicy}>
        <input
          type="checkbox"
          id="privacyPolicy"
          checked={isPrivacyChecked}
          onChange={handlePrivacyPolicyChange}
        />
        <label htmlFor="privacyPolicy">
          {t('signupPage.privacyPolicy1')}
          <a href="https://tracecrystal.com/styled/" target="_blank" rel="noopener noreferrer">
            {t('signupPage.privacyPolicy2')}
          </a>
          {t('signupPage.privacyPolicy3')}
        </label>
      </div>
    </Form>
  );
};

export default SignupPage;
