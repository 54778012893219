import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { RoutesEnum } from "../../constants/routesEnum";

import styles from './NavigationBar.module.css';
import { useAppDispatch, useAppSelector } from "../../store/rootReducer";
import logoIrisc from '../../assets/Logo2.png';
import iconHome from '../../assets/icons/home-navbar.svg';
import iconAuthCamera from '../../assets/icons/camera_authenticate.svg';
import iconLogin from '../../assets/icons/login.svg';
import iconAddCamera from '../../assets/icons/camera_add.svg';
import iconUser from '../../assets/icons/user.svg';

interface INavigationBar {
    resetCameraSteps: () => void;
  }
  
  const NavigationBar = ({ resetCameraSteps }: INavigationBar) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector((state) => state.auth);
    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
        setIsActive(true);
    }, [dispatch]);

    const isActiveTab = useCallback((isActive : boolean) => {
        return isActive ? 
        {
            backgroundColor: "var(--active-color)",
            borderRadius: "20px",
            padding: "0px 15px"
        }
        : { opacity: '0.5'};
    },[])

    const handleFAQClick = useCallback(() => {
        window.open("http://tracecrystal.com", '_blank');
    },[])

    const handleCameraClick = useCallback(() => {
        resetCameraSteps();
    },[resetCameraSteps])

    return (
        <div className={styles.slideIn}>
            <div className={`${styles.container} ${isActive ? styles.slideIn : ''}`}>
                <button className={styles.logoContainer} onClick={handleFAQClick}>
                    <img className={styles.imageIrisc} src={logoIrisc} alt="logo Irisc"/>
                </button>
                <NavLink to={RoutesEnum.HOME_PAGE} className={styles.navLink}  style={({ isActive }) => { return isActiveTab(isActive)}} >
                    <img className={styles.iconHome} src={iconHome} alt="" />
                    <p >{t('components.navigationBar.homeText')}</p>
                </NavLink>
                
                <NavLink to={RoutesEnum.CAMERA_PAGE} className={styles.navLink} style={({ isActive }) => { return isActiveTab(isActive)}} onClick={handleCameraClick}>
                    {!isAuth.isAuthenticated ? 
                        <>
                            <img className={styles.iconHome} src={iconAuthCamera} alt=""/>
                            <p>{t('components.navigationBar.cameraText')}</p>
                        </>
                    :
                        <>  
                            {isAuth.isEnrolling ? 
                                <>
                                    <img className={styles.iconHome} src={iconAddCamera} alt=""/>
                                    <p>{t('components.navigationBar.vendorCameraText')}</p>
                                </>
                            :
                                <>
                                    <img className={styles.iconHome} src={iconAuthCamera} alt=""/>
                                    <p>{t('components.navigationBar.cameraText')}</p>
                                </>
                            }
                        </>
                    }
                </NavLink>
                
                {isAuth.isAuthenticated ? (  
                    <NavLink to={RoutesEnum.VENDOR_PAGE} className={styles.navLink} style={({ isActive }) => { return isActiveTab(isActive)}}>
                        <img className={styles.iconHome} src={iconUser} alt=""/>
                        <p>{sessionStorage.getItem('user_name')}</p>
                    </NavLink>) 
                    : (  
                    <NavLink to={RoutesEnum.VENDOR_PAGE} className={styles.navLink} style={({ isActive }) => { return isActiveTab(isActive)}}>
                        <img className={styles.iconHome} src={iconLogin} alt="" />
                        <p>{t('components.navigationBar.vendorText')}</p>
                    </NavLink>)
                }
            </div>
        </div>
    );
}

export default NavigationBar;