import styles from './Overlay.module.css';

const Overlay = () => {
    return (
        <div className={styles.overlay}>
           <span className={styles.loader}></span>
        </div>
    );
};

export default Overlay;