import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAddUserMutation } from "../store/api/userApi";
import { RoutesEnum } from "../constants/routesEnum";
import { IUser } from "../constants/IUser";


const useHandleSignup = () => {
    const [addUser, { isLoading, isSuccess }] = useAddUserMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            navigate(RoutesEnum.VENDOR_PAGE);
            toast.success("Registration Complete.")
        } 
    }, [isSuccess, navigate]);

    const handleSignup = useCallback(async ({ name, email, password }: IUser) => {
        try {
            const user = {
                name: name,
                email: email,
                password: password,
            };

            await addUser(user).unwrap();
            return true;
        } catch (error) {
            console.error('Failed to add user:', error);
            toast.error("Registration Error.")
            return false;
        }
    }, [addUser]);

    return { handleSignup, isLoading};
}

export default useHandleSignup;