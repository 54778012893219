import { useState, useCallback } from 'react';

const useLoading = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSigninLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const handleSignupLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);
  const handleLogoutLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const handleCameraLoading = useCallback((loading:boolean) => {
    setIsLoading(loading);
  },[])

  const handleMyProductsLoading = useCallback((loading:boolean) => {
    setIsLoading(loading);
  },[])

  const handleMyProductImagesLoading = useCallback((loading:boolean) => {
    setIsLoading(loading);
  },[])
  
  return {
    isLoading,
    handleSigninLoading,
    handleSignupLoading,
    handleLogoutLoading,
    handleCameraLoading,
    handleMyProductsLoading,
    handleMyProductImagesLoading
  };
};

export default useLoading;
