export enum UrlEnum  {
    USER_URL = "/api/v1/auth",
    REGISTER_URL = "/register",
    LOGIN_URL = "/login",
    LOGOUT_URL =  "/single-logout",
    PRODUCTS_URL = "/api/v1/products",
    VENDOR_URL = "/api/v1/vendors",
    VALIDATE_URL = "/validate",
    REFRESH_URL = "/refresh-token",
    PRODUCT_IMAGES_URL = "/images",
    GET_LOCATION_URL = "/api/v1/location"
}