import { IForm } from "./IForm";
import { toast } from "react-toastify";

import styles from "./Form.module.css";


const Form: React.FC<IForm> = ({ children, id, rel, submitForm, validation, user, buttonText  }) => {

  const handleFormSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (validation.every(isValid => isValid)) { 
      const { name, email, password } = user; 
      submitForm(name, email, password);
    } else {
      toast.error('Wrong email and/or password');
    }
  };

  return (
    <form className={styles.form} id={id} rel={rel}>
      {children}
        <button
          className={`${styles.newButton}`}
          id={styles.submitFormButton}
          onClick={handleFormSubmit}
        > {buttonText} </button>
    </form>
  );
};

export default Form;