import { IValidationResponse } from "../../../constants/IProducts";
import { useGetProductsImagesQuery } from "../../../store/api/productsApi";

import passed from '../../../assets/shield.png';
import failed from '../../../assets/warranty.png'
import styles from './ValidateStep.module.css';
import { useTranslation } from "react-i18next";



interface IValidateStep {
    result: IValidationResponse;
    resetStep: () => void;
    titleStep: string;
}

const ValidateStep = ({ result, resetStep, titleStep }: IValidateStep) => {
    const {t} = useTranslation();
    const productId = result.product ? result.product.id : 0; 
    const { data, isLoading } = useGetProductsImagesQuery(productId, { skip: result.status === 'fail' });

    return (
        <>
        <h2 className={styles.titleStep}>{titleStep}</h2>
        <div className={styles.glass}>
            <div className={styles.results}>
                {(!result || result.status === 'fail') ? (
                    <>
                        <img className={styles.failImg} src={failed} alt="Failed" />
                        <p className={styles.validationFailed}>{t('UserCameraPage.validateStep.validationFailed')}</p>
                        <div className={styles.buttonContainer}>
                            <button className={styles.newButton} onClick={resetStep}>{t('UserCameraPage.validateStep.retry')}</button>
                        </div>
                    </>
                ) : (
                    <>
                        <img className={styles.validationImg} src={passed} alt="Passed" />
                        <p className={styles.validationPassed}>{t('UserCameraPage.validateStep.validationPassed')}</p>
                        <div className={styles.infoContainer}>
                            <h2 className={styles.infoTitle}>{t('UserCameraPage.validateStep.productInformation')}</h2>
                            <div className={styles.detailsImageContainer}>
                                {!isLoading &&  data && data[0]?.image_blob.length > 0  && <img src={`data:image/png;base64, ${ data[0]?.image_blob}`} alt='' className={styles.detailsCsrImage} />}
                      
                            </div>
                            <p className={styles.metadata}>{t('UserCameraPage.validateStep.name')} {result.product.name || t('UserCameraPage.na')}</p>
                            <p className={styles.metadata}>{t('UserCameraPage.validateStep.description')} {result.product.description || t('UserCameraPage.na')} </p>
                            <p className={styles.metadata}>{t('UserCameraPage.validateStep.location')}  
                                {result.product.production_country && result.product.production_city 
                                    ? `${result.product.production_country}, ${result.product.production_city}`
                                    : t('UserCameraPage.na')
                                }
                            </p>
                            <p className={styles.metadata}>{t('UserCameraPage.validateStep.productCode')} {result.product.product_code || t('UserCameraPage.na')}</p>
                        </div>
                    </>
                )}
            </div>
        </div>
        </>
    );
};

export default ValidateStep;