import { useCallback, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { CameraPage, FaqPage, HomePage, MainPage, VendorPage, WelcomePage, SigninPage, SignupPage } from "./pages";
import { RoutesEnum } from "./constants/routesEnum";
import Overlay from "./components/Overlay/Overlay";
import useLoading from "./hooks/useLoading";
import useAuth from "./hooks/useAuth";
import { useAppDispatch } from "./store/rootReducer";
import { setAuth } from "./store/slices/userSlice";

import './i18n';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  const { handleSigninLoading, handleSignupLoading, handleCameraLoading, handleMyProductsLoading, handleMyProductImagesLoading, isLoading } = useLoading();
  const isAuthenticated = useAuth();
  const dispatch = useAppDispatch();

  const resetStepsRef = useRef<() => void>(() => {});
  
  useEffect(() => {
    dispatch(setAuth(isAuthenticated));
  },[dispatch, isAuthenticated])

  const handleResetSteps = useCallback((resetSteps: () => void) => {
    resetStepsRef.current = resetSteps;
  }, []);
  
  const resetCameraSteps = useCallback(() => {
    if (resetStepsRef.current) {
      resetStepsRef.current();
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={RoutesEnum.WELCOME_PAGE} element={<WelcomePage />} />
          <Route path={RoutesEnum.MAIN_PAGE} element={<MainPage resetCameraSteps={resetCameraSteps}/>} >
            <Route path={RoutesEnum.HOME_PAGE} element={<HomePage handleMyProductsLoading={handleMyProductsLoading}/>} />
            <Route path={RoutesEnum.CAMERA_PAGE} element={<CameraPage handleCameraLoading={handleCameraLoading} handleMyProductsLoading={handleMyProductsLoading} handleMyProductImagesLoading={handleMyProductImagesLoading} handleResetSteps={handleResetSteps}/>} />
            <Route path={RoutesEnum.FAQ_PAGE} element={<FaqPage />} />
            <Route path={RoutesEnum.VENDOR_PAGE} element={<VendorPage handleSigninLoading={handleSigninLoading} handleSignupLoading={handleSignupLoading} handleMyProductsLoading={handleMyProductsLoading}/>} />
            <Route path={RoutesEnum.SIGNIN_PAGE} element={<SigninPage handleSigninLoading={handleSigninLoading} />} />
            <Route path={RoutesEnum.SIGNUP_PAGE} element={<SignupPage  handleSignupLoading={handleSignupLoading}/>} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position="top-right" autoClose={2000}  closeOnClick rtl={false} draggable  theme="light"/>
      {isLoading && <Overlay/>}
    </div>
  );
}

export default App;
