import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';

import { useLoginUserMutation } from "../store/api/userApi";
import { RoutesEnum } from "../constants/routesEnum";
import { jwtDecode } from "jwt-decode";


interface IUseHandleSignin {
    email:string;
    password:string;
}

interface JwtPayload {
    user_name: string;
    email: string;
}

const useHandleSignin = () => {
    const [logUser, { isLoading, isSuccess }] = useLoginUserMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            navigate(RoutesEnum.HOME_PAGE);
            toast.success("Login Succesfully.")
        }
    }, [ isSuccess, navigate]);
    
    const handleSignin = useCallback(async ({email, password} : IUseHandleSignin) => {

        try {
            const result =await logUser({email, password} ).unwrap();

            if (result.access_token && result.refresh_token) {
                const decodedToken = jwtDecode<JwtPayload>(result.access_token);
                sessionStorage.setItem('access_token', result.access_token);
                sessionStorage.setItem('refresh_token', result.refresh_token);
                sessionStorage.setItem('email', decodedToken.email);
                sessionStorage.setItem('user_name', decodedToken.user_name);

                return true;
            } else {
                console.error("Tokens not received from server");
                toast.error("Error during login. Please try again.");

                return false;
            }
        } catch (error) {
            console.error("Error during login:", error);
            toast.error("Error during login. Please try again.");
            return false;
        }
    },[logUser])



    return { handleSignin, isLoading};
}

export default useHandleSignin;