import VendorCameraPage from "./VendorCameraPage";
import UserCameraPage from "./UserCameraPage";

import styles from './CameraPage.module.css';
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/rootReducer";
import { useEffect } from "react";
import { setEnrolling } from "../../store/slices/userSlice";

interface ICameraPage {
  handleCameraLoading: (isLoading: boolean) => void;
  handleMyProductsLoading: (isLoading: boolean) => void;
  handleMyProductImagesLoading: (isLoading: boolean) => void;
  handleResetSteps: (resetSteps: () => void) => void;
}

const CameraPage = ({ handleCameraLoading, handleMyProductsLoading, handleMyProductImagesLoading, handleResetSteps }: ICameraPage) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const isEnrolling = useAppSelector((state) => state.auth.isEnrolling);
  const { cameraPageSelected } = location.state || (isEnrolling ? { cameraPageSelected: 'newCsr' } : { cameraPageSelected: 'validate' });

  useEffect(() => {
    cameraPageSelected === 'newCsr' ? dispatch(setEnrolling(true)) : dispatch(setEnrolling(false))
  },[cameraPageSelected, dispatch])

  return (
    <div className={styles.container}>
      {isAuthenticated && !isEnrolling && <UserCameraPage  handleCameraLoading={handleCameraLoading} handleResetSteps={handleResetSteps}/> }
      {isAuthenticated && isEnrolling && <VendorCameraPage handleCameraLoading={handleCameraLoading} handleMyProductsLoading={handleMyProductsLoading} handleMyProductImagesLoading={handleMyProductImagesLoading} handleResetSteps={handleResetSteps}/> }
      {!isAuthenticated &&  <UserCameraPage handleCameraLoading={handleCameraLoading} handleResetSteps={handleResetSteps} />}
  
    </div>
  );
};

export default CameraPage;
