
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CameraStep, CsrIdStep, ValidateStep } from '../../components';
import Overlay from '../../components/Overlay/Overlay';
import { IValidationRequest, IProduct, IValidationResponse, initialCsr, initialValidatedImage } from '../../constants/IProducts';
import { ILocationRequest, ILocationResponse, initialLocationRequest, initialLocationResponse } from '../../constants/ILocation';
import { useValidateImageMutation } from '../../store/api/productsApi';
import { useGetLocationQuery } from '../../store/api/locationApi';

import styles from './CameraPage.module.css';
import { t } from 'i18next';

interface IUserCameraPage {
    handleCameraLoading : (isLoading: boolean) => void;
    handleResetSteps: (resetSteps: () => void) => void;
}


const UserCameraPage = ({ handleCameraLoading, handleResetSteps}: IUserCameraPage) => {
    const [csrId, setCsrId] = useState<IValidationRequest>(initialCsr);
    const [validationResult, setValidationResult] = useState<IValidationResponse>(initialValidatedImage);
    const [userPosition, setUserPosition] = useState<ILocationRequest>(initialLocationRequest);
    const [userLocation, setUserLocation] = useState<ILocationResponse>(initialLocationResponse);
    const [currentStep, setCurrentStep] = useState<number>(0);

    const [ validateImage, { isLoading } ] = useValidateImageMutation();
    const { data, isLoading : isLoadingLocation, isError, error } = useGetLocationQuery(
        {latitude: userPosition.latitude, longitude: userPosition.longitude}, 
        { skip: ( userPosition.latitude === 0 && userPosition.longitude === 0 ), refetchOnMountOrArgChange: true});

    const getUserLocation =  useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setUserPosition({ latitude: latitude, longitude: longitude });
            }, (error) => {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        setUserPosition(initialLocationRequest);
                        setUserLocation(initialLocationResponse); // usa warning sistema testo
                        toast.warning('You denied the request for Geolocation. Enable location services to automatically insert your location.');
                        break;
                    case error.POSITION_UNAVAILABLE:
                        setUserPosition(initialLocationRequest);
                        setUserLocation(initialLocationResponse);
                        toast.warning('Location information is unavailable. Please try again or insert manually.');
                        break;
                    case error.TIMEOUT:
                        setUserPosition(initialLocationRequest);
                        setUserLocation(initialLocationResponse);
                        toast.warning('The request to get user location timed out. Please try again or insert manually.');
                        break;
                    default:
                        setUserPosition(initialLocationRequest);
                        setUserLocation(initialLocationResponse);
                        toast.warning('An error occurred while fetching user location. Please try again later.');
                }
            }
        );
        }
        else {
            toast.warning('Geolocation is not supported by this browser.');
        }
    },[]);
    const nextStep = useCallback(() => {
        if (currentStep < 3) 
            setCurrentStep(currentStep + 1);
    },[currentStep]);

    const resetStep = useCallback(() => {
        setCurrentStep(0);
    }, []);


    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "csr_code") {
            const parsedValue = parseInt(value);
            parsedValue > 0 ? setCsrId({ ...initialCsr, csr_code: parsedValue }) : setCsrId(initialCsr);
        } else {
            setUserLocation(prevGpsLocation => ({
                ...prevGpsLocation,
                [name]: value
            }));
        }
    }, []);

    const handleSelect = useCallback( async () => {
        nextStep();
    },[nextStep])

    const confirmImage = useCallback(async (image: IProduct) => {
        try {
                const result = await validateImage({csr_code: csrId.csr_code ,csr_image: image.csr_image, city: userLocation.city, country: userLocation.country}).unwrap();
                setValidationResult(result);
        } catch (error) {
            setValidationResult(initialValidatedImage);
            console.log(error)
        }
        nextStep();
      },[csrId.csr_code, nextStep, userLocation.city, userLocation.country, validateImage]); 


    useEffect(() => {
        getUserLocation();
    },[ getUserLocation])

    useEffect(() => {
        data?.city ? setUserLocation({city: data.city, country: data?.country}) : setUserLocation(initialLocationResponse);
        if(isError){
            toast.warning("no valid location retrieved from position. Please insert manually.");
        }
    },[data?.city, data?.country, error, isError])
    
    useEffect(() => {
        handleCameraLoading(isLoading);
      },[handleCameraLoading, isLoading])

    useEffect(() => {
        handleResetSteps(resetStep);
    }, [handleResetSteps, resetStep]);

     
    return (
        <>
            <div className={styles.stepper}>
                <div className={styles.stepperLine}></div>
                <button className={`${styles.stepperButton} ${currentStep === 0 ? styles.active : ''}`}>1</button>
                <button className={`${styles.stepperButton} ${currentStep === 1 ? styles.active : ''}`}>2</button>
                <button className={`${styles.stepperButton} ${currentStep === 2 ? styles.active : ''}`}>3</button>
            </div>
            {currentStep === 0  &&( <CsrIdStep csrId={csrId} handleChange={handleChange} handleSelect={handleSelect} location={userLocation} titleStep={t('UserCameraPage.authenticate')}/> )}
            {currentStep === 1 && ( <CameraStep confirmedImage={confirmImage} titleStep={t('UserCameraPage.takePhoto')}/>)}
            {currentStep === 2 && ( <ValidateStep result={validationResult} resetStep={resetStep} titleStep={t('UserCameraPage.resultAuthenticate')}/>)}
            {isLoadingLocation && < Overlay />}
       </>
    )
}

export default UserCameraPage;

