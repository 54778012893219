import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/userSlice'; 
import { userDataApi } from './api/userApi';
import { productsApi } from './api/productsApi';
import { locationApi } from './api/locationApi';

const rootReducer = combineReducers({
  auth: authReducer, 
  [userDataApi.reducerPath]: userDataApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(userDataApi.middleware, productsApi.middleware, locationApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
