import { useState } from "react";
import Label from "../Label";
import { IEmailInput } from "./IEmailInput";
import { emailSchema } from "./schema/emailSchema";
import { useTranslation } from 'react-i18next';

import styles from './EmailInput.module.css'

const EmailInput = ({
  form,
  value,
  onChange,
  onValidationChange,
}: IEmailInput) => {
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(event);

    emailSchema
      .validate(value)
      .then(() => {
        setValidationError(null);
        onValidationChange('isMailValid',true);
      })
      .catch((error) => {
        setValidationError(error.message);
        onValidationChange("isMailValid",false);
      });

  };

  return (
    <div className={styles.userBox}>
      <input 
        required 
        name="email" 
        type="text"
        id={styles.emailInput} 
        value={value}
        onChange={handleChange}
      />
      <label className={styles.label}>{t('components.emailInput.placeholder')}</label>
      {validationError && <Label className={styles.errorEmailInputLabel}  id={styles.errorEmailInputLabel} htmlFor='emailInput' form={form} labelText={validationError}/>}
  </div>
  );
};

export default EmailInput;
