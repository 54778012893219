import {  useCallback,  useEffect,  useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setEnrolling } from '../../store/slices/userSlice';
import { SigninPage, SignupPage } from '../../pages';
import { useAppDispatch, useAppSelector } from '../../store/rootReducer';

import styles from './VendorPage.module.css';
import VendorProfile from '../../components/Profile/VendorProfile';
import { MyProductsModal } from '../../components';


interface IVendorPage {
    handleSigninLoading: (isLoading: boolean) => void;
    handleSignupLoading: (isLoading: boolean) => void;
    handleMyProductsLoading: (isLoading: boolean) => void;
}

const VendorPage = ({ handleSigninLoading, handleSignupLoading, handleMyProductsLoading }:IVendorPage) => {
    const { t } = useTranslation();
    const dispatch= useAppDispatch();
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

    const [isMyCsrIdsOpen, setIsMyCsrIdsOpen] = useState<boolean>(false);
    const openMyCsrId = useCallback(() => setIsMyCsrIdsOpen(true), []);
    const closeMyCsrId = useCallback(() => setIsMyCsrIdsOpen(false), []);

    const [isSignupPage, setIsSignupPage] = useState<boolean>(false);

    useEffect(() => { dispatch(setEnrolling(true))},[dispatch])

    const handleSignupPage = useCallback(() => {
        setIsSignupPage(false);
    },[])

    return (
        <div className={styles.container}>
            <div className={styles.glass}>
                {!isAuthenticated ? (
                        <div className={styles.buttonContainer}>
                            {isSignupPage ? 
                                <div className={styles.signContainer}>
                                    <p className={styles.title}>{t('vendorPage.signUp')}</p>
                                    <SignupPage handleSignupLoading={handleSignupLoading} handleSignupPage={handleSignupPage}/> 
                                </div>
                            : 
                                <div className={styles.signContainer}>
                                    <p className={styles.title}>{t('vendorPage.signIn')}</p>
                                    <SigninPage handleSigninLoading={handleSigninLoading} />
                                </div>
                            }
                            {!isSignupPage ? (
                                      <p className={styles.span}>{t('vendorPage.notRegistered')} 
                                        <button className={`${styles.a2}`} onClick={() => setIsSignupPage(true)}>
                                            {t('vendorPage.signUp')}
                                        </button>
                                    </p>

                            ) : (
                                    <span className={styles.span}>{t('vendorPage.alreadyRegistered')}
                                        <button className={`${styles.a2}`} onClick={() => setIsSignupPage(false)}>
                                            {t('vendorPage.signIn')}
                                        </button>
                                    </span>
                            )}
                        </div>
                ) : (
                    <VendorProfile t={t}  handleMyCsrIdsOpen={()=>openMyCsrId()}/>
                )}
            </div>
            {isMyCsrIdsOpen && <MyProductsModal onClose={closeMyCsrId} handleMyProductsLoading={()=>handleMyProductsLoading}/>}
        </div>
    );
};

export default VendorPage;
