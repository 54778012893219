export const fr = {
    welcomePage: {
        csrTitle: "CSR",
        title: "Authenticator",
        subtitle: "Say goodbye to counterfeiting Products",
        getStarted: "Get Started"
    },
    homePage: {
        welcomeTo: "Welcome To",
        authenticator: "CSR Authenticator",
        generateCsrId: "Generate new CaeSaR ID",
        validateCsrId: "Validate a CaeSaR ID"
    },
    faqPage: {
        faqTitle: "What is CSRs",
        faqText: "ChLCs in spherical shape are called Cholesteric Spherical Reflectors (CSRs)"
    },
    signinPage: {
        traceCrystalLogo: "Trace Crystal Logo",
        signIn: "Sign In"
    },
    signupPage: {
        traceCrystalLogo: "Trace Crystal Logo",
        signUp: "Sign Up"
    },
    vendorPage: {
        traceCrystalLogo: "Trace Crystal Logo",
        signIn: "Sign In",
        signUp: "Sign Up",
        logout: "Logout",
        titleText: "Are you a Vendor?",
        alreadyRegistered: "You are already registered?",
        notRegistered: "Not registered yet?"
    },
    cameraPage: {
        discard: "Discard",
        confirm: "Confirm",
        youMustInsertCsrId: "You must insert a CaeSaR ID first",
        errorPostingImage: "Error posting image",
        errorValidatingImage: "Error validating image"
    },
    VendorCameraPage: {
        gallery: "Gallery",
        myImages: "My Images"
    },
    UserCameraPage: {
        gallery: "Gallery",
        placeholder: "Insert CSR_ID"
    },
    components:{
        emailInput: {
            placeholder: "example@something.it"
        },
        genericInput: {
            placeholder: "Username"
        },
        passwordInput: {
            placeholder: "********",
        },
        navigationBar: {
            homePlaceholder: "Home",
            cameraPlaceholder: "Camera",
            faqPlaceholder: "FAQ",
            vendorPlaceholder: "Vendor",
            homeText: "Home",
            signedCameraText: "Camera",
            unsignedCameraText: "Authenticate",
            faqText: "FAQ",
            vendorText: "Vendor",
            userText: "Signin"
        }
    },
    steps: {
        stepOne: "1",
        stepTwo: "2",
        stepThree: "3"
    }
}