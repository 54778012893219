import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesEnum } from '../constants/routesEnum';
import useLoading from './useLoading';
import { useLogoutUserMutation } from '../store/api/userApi';
import { setAuth } from '../store/slices/userSlice';
import { useAppDispatch } from '../store/rootReducer';

const useHandleLogout = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { handleLogoutLoading } = useLoading();
    const [ logoutUser, {isLoading} ] = useLogoutUserMutation();

    useEffect(() => {
        handleLogoutLoading(isLoading);
      },[handleLogoutLoading, isLoading])

    const logout = useCallback(async () => {
        try {
            const refreshToken = sessionStorage.getItem('refresh_token') ?? '' ;
            await logoutUser(refreshToken);

            sessionStorage.removeItem('email');
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('refresh_token');
            navigate(RoutesEnum.HOME_PAGE);
            dispatch(setAuth(false));
        } catch (error) {
            console.error('Error during logout:', error);
        }
    }, [dispatch, logoutUser, navigate]);

    return  logout ;
};

export default useHandleLogout;