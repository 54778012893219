import { useState, ChangeEvent } from "react";

import { genericInputSchema } from "./schema/genericInputSchema";
import { IGenericInput } from "./IGenericInput";
import Label from "../Label";

import styles from './GenericInput.module.css';


const GenericInput = ({
    form,
    value,
    onValidationChange,
    onChange,
    type = "text",
    name
}: IGenericInput) => {
    const [validationError, setValidationError] = useState<string | null>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        onChange(event);
        
        genericInputSchema
            .validate(value)
            .then(() => {
                setValidationError(null);
                onValidationChange('isUsernameValid', true);
            })
            .catch((error) => {
                setValidationError(error.message);
                onValidationChange("isUsernameValid", false);
            });
    };

    return (
        <div className={styles.userBox}>
            <input
                name={name}
                autoComplete="off"
                type={type}
                className={styles.genericInput}
                id={styles.genericInput}
                required
                value={value}
                onChange={handleChange}
            ></input>
             <label className={styles.label}>Username</label>
            {validationError && <Label className={styles.errorGenericInputLabel} id={styles.errorGenericInputLabel}  htmlFor='genericInput' form={form} labelText={validationError} />}
        </div>
    );
};

export default GenericInput;