
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IValidationRequest } from '../../../constants/IProducts';
import { ILocationResponse } from '../../../constants/ILocation';

import styles from './CsrIdStep.module.css'

interface ICsrIdStep {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSelect: () => void;
    csrId: IValidationRequest;
    location: ILocationResponse;
    titleStep: string;
}

const CsrIdStep = ({ csrId, handleChange, handleSelect, location, titleStep }: ICsrIdStep) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [gpsLocation, setGpsLocation] = useState<ILocationResponse>(location);

    useEffect(() => {
        setGpsLocation(location)
    },[location])
    
    const isNonEmptyString = (str: string) => str.trim().length > 0;

    const handleChangeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;  

        if (name === "csr_code" && value.length <= 6) {
                setInputValue(value);
                handleChange(event);
        } else {
            setGpsLocation((prevGpsLocation) => ({
                ...prevGpsLocation,
                [name]: value
            }))
        }
        handleChange(event);
    },[handleChange]);

    const handleClick = useCallback(() => {
        if(inputValue.length === 6) 
            handleSelect();
    },[handleSelect, inputValue.length]);

    const isFieldsOk = useMemo(() => {
        if(inputValue.length === 6 && isNonEmptyString(gpsLocation.city) && isNonEmptyString(gpsLocation.country))
            return true;
        return false;
    },[gpsLocation.city, gpsLocation.country, inputValue.length])

    const nextStep = useCallback((event: KeyboardEvent) => {
        if ( isFieldsOk && event.key === 'Enter') 
            handleSelect();
    }, [handleSelect, isFieldsOk]);

    useEffect(() => {
        document.addEventListener('keydown', nextStep);
        return () => {
            document.removeEventListener('keydown', nextStep);
        };
    }, [nextStep]);

    return (
        <>
        <h2 className={styles.titleStep}>{titleStep}</h2>
        <div className={styles.glass}>
             <h2 className={styles.CsrIdTitle}>Insert your CaeSaR ID:</h2>
            <div className={styles.userBox}>
                    <input 
                        ref={inputRef}
                        name="csr_code" 
                        type="number"
                        id={styles.genericInput }
                        value={inputValue}
                        onChange={handleChangeInput}
                        autoComplete='off'
                    />  
                 <label className={csrId.csr_code === 0 ? styles.label : `${styles.label} ${styles.hLabel} `}>{t('UserCameraPage.csridPlaceholder')}</label>  
                 {(inputValue.length < 6 && inputValue.length > 0) && <label className={styles.errorGenericInputLabel} htmlFor='genericInput' >CaeSaR ID must be six numbers</label>}
            </div>
            <div className={styles.userBox}>
                    <input 
                        ref={inputRef}
                        name="city" 
                        type="text"
                        id={styles.genericInput}
                        value={location.city}
                        onChange={handleChangeInput}
                        autoComplete='off'
                    />  
                 <label className={location.city === '' ? styles.label : `${styles.label} ${styles.hLabel} `}>{t('UserCameraPage.cityPlaceholder')}</label>  
                 {(gpsLocation.city === "") && <label className={styles.errorGenericInputLabel} htmlFor='genericInput' >City can't be empty</label>}
            </div>
            <div className={styles.userBox}>
                    <input 
                        ref={inputRef}
                        name="country" 
                        type="text"
                        id={styles.genericInput }
                        value={location.country}
                        onChange={handleChangeInput}
                        autoComplete='off'
                    />  
                 <label className={location.country === '' ? styles.label : `${styles.label} ${styles.hLabel} `}>{t('UserCameraPage.townPlaceholder')}</label>  
                 {(gpsLocation.country === "") && <label className={styles.errorGenericInputLabel} htmlFor='genericInput' >Country can't be empty</label>}
            </div>
            <div className={styles.nextContainer}>
                <button className={isFieldsOk ? styles.newButton : `${styles.newButton} ${styles.disabledButton}`} onClick={handleClick}>Next Step</button>
            </div>    
        </div>
        </>
    )
}

export default CsrIdStep;