import styles from './VendorProfile.module.css';
import useHandleLogout from '../../hooks/useHandleLogout';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../constants/routesEnum';
import iconAddCamera from '../../assets/icons/camera_add.svg';
import iconProduct from '../../assets/icons/product.svg';
import iconLogout from '../../assets/icons/logout.svg';

const VendorProfile = ({ t,  handleMyCsrIdsOpen }:any) => {
    const logout = useHandleLogout();
    const navigate = useNavigate();

    const openMyCsrId = useCallback(() => {
        handleMyCsrIdsOpen();
    }, [handleMyCsrIdsOpen]);
    
    const openCamera = useCallback((selectedPage: 'newCsr' | 'validate') => {
        navigate(RoutesEnum.CAMERA_PAGE, { state: { cameraPageSelected: selectedPage } });
    }, [navigate]);
  
    
    return (
        <>
            <div className={styles.buttonContainer}>
                <div className={styles.textContainer}>
                    <p className={styles.text}>{t('vendorPage.name')}: {sessionStorage.getItem('user_name')} </p>
                    <p className={styles.text}>{t('vendorPage.email')}: {sessionStorage.getItem('email')} </p>
                </div>
                <button className={styles.newButton} onClick={()=> openCamera('newCsr')}>
                    <img className={styles.image} src={iconAddCamera} alt=''/>
                    {t('vendorPage.enrollment')}
                </button>
                <button className={styles.newButton} onClick={openMyCsrId}>
                    <img className={styles.image} src={iconProduct} alt=''/>
                    {t('vendorPage.products')}
                </button>
                <button className={styles.newButton} onClick={logout}>
                    <img className={styles.image} src={iconLogout} alt=''/>
                    {t('vendorPage.logout')}
                </button>
            </div>
        </>
    )
}

export default VendorProfile;