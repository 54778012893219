import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RoutesEnum } from '../../constants/routesEnum';
import { MyProductsModal } from '../../components';

import logo from '../../assets/LogoFull1.png'
import iconAddCamera from '../../assets/icons/camera_add.svg';
import iconAuthCamera from '../../assets/icons/camera_authenticate.svg';
import iconProduct from '../../assets/icons/product.svg';
import styles from './HomePage.module.css'
import { useAppDispatch, useAppSelector } from '../../store/rootReducer';
import { setEnrolling } from '../../store/slices/userSlice';

interface IHomePage {
    handleMyProductsLoading: (isLoading: boolean) => void;
}

const HomePage = ({handleMyProductsLoading} : IHomePage) => {
    const [isMyCsrIdsOpen, setIsMyCsrIdsOpen] = useState<boolean>(false);
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector((state) => state.auth.isAuthenticated);

    const openMyCsrId = useCallback(() => setIsMyCsrIdsOpen(true), []);
    const closeMyCsrId = useCallback(() => setIsMyCsrIdsOpen(false), []);

    useEffect(() => { dispatch(setEnrolling(true))},[dispatch])

    const openCamera = useCallback((selectedPage: 'newCsr' | 'validate') => {
        navigate(RoutesEnum.CAMERA_PAGE, { state: { cameraPageSelected: selectedPage } });
    }, [navigate]);
    

    return (
        <div className={styles.container}>
            <div className={styles.glass}>
                <div className={styles.imgContainer}>
                    <img className={styles.image} alt='Trace Crystal Logo' src={logo} />
                </div>
                <div className={styles.textContainer}>
                    <p className={styles.titleHome}>{t('homePage.welcomeTo')}</p>
                    <p className={styles.subTitleHome}>{t('homePage.authenticator')}</p>
                </div>
                <div className={styles.buttonContainer}>
                    <button className={`${styles.newButton} `} onClick={() => openCamera('validate')}>
                       <img className={styles.icon} src={iconAuthCamera} alt=''/>
                       {t('homePage.validateCsrId')}
                    </button>
                    {isAuth && 
                        <>
                            <button className={`${styles.newButton}`} onClick={() => openCamera('newCsr')}>
                                <img className={styles.icon} src={iconAddCamera} alt=''/>    
                                {t('homePage.generateCsrId')}
                            </button>
                            <button className={`${styles.newButton}`} onClick={openMyCsrId}>
                                <img className={styles.icon} src={iconProduct} alt=''/>
                                {t('VendorCameraPage.myImages')}
                            </button>
                        </>
                    }
                </div>
            </div> 
            {isMyCsrIdsOpen && <MyProductsModal  onClose={closeMyCsrId} handleMyProductsLoading={handleMyProductsLoading}/>}
        </div>
    )
}

export default HomePage;

