
  interface PixelCrop {
    x: number;
    y: number;
    width: number;
    height: number;
  }
  interface ResizeOptions {
    width: number;
    height: number;
  }

export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  
    export function getRadianAngle(degreeValue: number): number {
        return (degreeValue * Math.PI) / 180;
      }
  
  /**
   * Returns the new bounding area of a rotated rectangle.
   */
  export function rotateSize(width: number, height: number, rotation: number): { width: number; height: number } {
    const rotRad = getRadianAngle(rotation);
  
    return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
  }
  
  /**
   * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
   */
  export default async function getCroppedImg(
    imageSrc: string,
    pixelCrop: PixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
    resize?: ResizeOptions
  ): Promise<string | null> {
    const image = await createImage(`data:image/jpeg;base64,${imageSrc}`);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    if (!ctx) {
      return null;
    }
  
    const rotRad = getRadianAngle(rotation);
  
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
  
    // Set each dimension to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;
  
    // Translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-safeArea / 2, -safeArea / 2);
  
    // Draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);
  
    // Set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
  
    // Paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );
  
    return new Promise((resolve, reject) => {
      try {
        if (resize) {
          const oc = document.createElement('canvas');
          const octx = oc.getContext('2d');
          if (!octx) {
            reject(new Error('Failed to get 2D context'));
            return;
          }
          oc.width = resize.width;
          oc.height = resize.height;
          octx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, resize.width, resize.height);
          resolve(oc.toDataURL('image/jpeg'));
        } else {
          resolve(canvas.toDataURL('image/jpeg'));
        }
      } catch (error) {
        reject(error);
      }
    });
  }
