import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IUser, IValidation, initialUserState, initialValidationState } from "../../constants/IUser";
import { Form, EmailInput, PasswordInput } from "../../components"; 
import useHandleSignin from "../../hooks/useHandleSignin";

import styles from './SigninPage.module.css';

interface ISigninPage {
  handleSigninLoading: (isLoading: boolean) => void;
}

const SigninPage = ({handleSigninLoading}:ISigninPage) => {
    const { t } = useTranslation();
    const { handleSignin, isLoading } = useHandleSignin();

    const [user, setUser] = useState<IUser>(initialUserState);
    const [validation, setValidation] = useState<IValidation>(initialValidationState);

    useEffect(() => {
        handleSigninLoading(isLoading);
      },[handleSigninLoading, isLoading])

    const handleSubmit = useCallback(() => { 
        handleSignin({ email: user.email, password: user.password });
    },[handleSignin, user.email, user.password]);

    const onSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser((prev) => ({ ...prev, [name]: value }));
    };
    const handleValidation = (fieldName: string, isValid: boolean) => {
        setValidation((prev) => ({
            ...prev,
            [fieldName]: isValid,
        }));
    };

    return (
        <Form className={styles.signinForm} id="SigninForm" rel="noreferrer" submitForm={handleSubmit} validation={Object.values(validation)} user={user} buttonText={t('signinPage.signIn')}>
            <EmailInput
                onChange={onSubmit}
                value={user.email}
                onValidationChange={handleValidation}
            />
            <PasswordInput
                onChange={onSubmit}
                value={user.password}
                onValidationChange={handleValidation}
                showValidationLabel={false}
                labelValue="Password"
            />
        </Form>
    );
};

export default SigninPage;