import {  useCallback, useEffect,  useState } from 'react';
import { toast } from 'react-toastify';

import { usePostProductMutation, usePostProductImagesMutation  } from "../../store/api/productsApi";
import { IProduct, IProductImageRequest, IProductImages, IResponseProduct,  initialResponseProduct } from '../../constants/IProducts';
import { CameraStep, InsertMetaDataStep, ResultStep } from '../../components';
import { t } from 'i18next';

import styles from './CameraPage.module.css';

interface IVendorCameraPage {
  handleCameraLoading : (isLoading: boolean) => void;
  handleMyProductsLoading: (isLoading: boolean) => void;
  handleMyProductImagesLoading: (isLoading: boolean) => void;
  handleResetSteps: (resetSteps: () => void) => void;
}


const VendorCameraPage = ({ handleCameraLoading, handleMyProductsLoading, handleMyProductImagesLoading, handleResetSteps} : IVendorCameraPage) => {
  const [postProduct, { isLoading } ] = usePostProductMutation();
  const [postProductImages, { isLoading: isImageLoading } ] = usePostProductImagesMutation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [product, setProduct] = useState<IResponseProduct>(initialResponseProduct);
  const [productImages, setProductImages] = useState<IProductImages>([])

  useEffect(() => {
    handleCameraLoading(isLoading);
  },[handleCameraLoading, isLoading]) 
  useEffect(() => {
    handleMyProductImagesLoading(isImageLoading)
  },[handleMyProductImagesLoading, isImageLoading])
  

  const nextStep = useCallback(() => {
    if (currentStep <= 3) {
        setCurrentStep(currentStep + 1);
    }
  },[currentStep]);
  const resetStep = useCallback(() => {
    setCurrentStep(0);
  }, []);

  const confirmImage = useCallback(async (image: IProduct) => {
    if (image.csr_image) {
      setProduct(prevProduct => ({
        ...prevProduct,
        csr_image: image.csr_image
      }));
        nextStep();
    }
}, [nextStep]);

  const setMetaData = useCallback(async (data: IProduct) => {
    try {
      const result = await postProduct({...data, csr_image : product.csr_image}).unwrap();
      setProduct(result); 
      nextStep();
      toast.success('Product Enrolled Correctly')  
    } catch (error) {
      toast.error('Data upload  is gone wrong')  
    }
  },[nextStep, postProduct, product.csr_image])

  const setImagesMetaData = useCallback(async (productImages: IProductImages) => {
    setProductImages(productImages);
  }, []); 

  const postImages =  useCallback(async(postRequestData : IProductImageRequest) => {
    if(postRequestData.productImages.length > 0)
      try {
        await postProductImages(postRequestData).unwrap();
      } catch {
        toast.error('Images Upload gone wrong') 
      }
  },[ postProductImages]);

  useEffect(() => {
    if(product.id !== 0){
      const postRequestData: IProductImageRequest = {
        id: product.id,
        productImages: productImages.map(image => ({
            base64_image: image.base64_image,
            is_main: image.is_main
        }))
      }
      postImages(postRequestData);
    }
  },[postImages, product.id, productImages]);

  useEffect(() => {
    handleResetSteps(resetStep);
}, [handleResetSteps, resetStep]);

  return (
        <>
          {currentStep === 0 && ( <CameraStep  confirmedImage={confirmImage} titleStep={t('VendorCameraPage.enrollment')}/> )}
          {currentStep === 1 && ( <InsertMetaDataStep setMetaData={setMetaData} setImagesMetaData={setImagesMetaData} /> )}
          {currentStep === 2 && ( <ResultStep product={product} productImages={productImages[0]} handleMyProductsLoading={handleMyProductsLoading} resetStep={resetStep}/> )}
          <div className={styles.stepper}>
            <div className={styles.stepperLine}></div>
            <button className={`${styles.stepperButton} ${currentStep === 0 ? styles.active : ''}`}>1</button>
            <button className={`${styles.stepperButton} ${currentStep === 1 ? styles.active : ''}`}>2</button>
            <button className={`${styles.stepperButton} ${currentStep === 2 ? styles.active : ''}`}>3</button>
        </div>
        </>
  )
}

export default VendorCameraPage