

const useAuth = () => {
        const storedUsername = sessionStorage.getItem("email");
        const storedToken = sessionStorage.getItem("access_token");

        if (storedUsername && storedToken) {
            return true;
        } else {
            return false;
        }
};

export default useAuth;