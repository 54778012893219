import { useState } from "react";

import Label from "../Label";
import { IPasswordInput  } from "./IPasswordInput";
import { passwordSchema } from "./schema/passwordSchema";

import eye_blind_icon from "../../assets/icons/eye-blind-icon.svg";
import eye_icon from "../../assets/icons/eye-icon.svg";
import styles from './PasswordInput.module.css';


const PasswordInput = ({
  form,
  value,
  onChange,
  onValidationChange,
  showValidationLabel,
  labelValue 
}: IPasswordInput) => {
  const [validationError, setValidationError] = useState<string | null>(null);;
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eye_blind_icon);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(event);

    passwordSchema
      .validate(  value )
      .then(() => {
        setValidationError(null);
        onValidationChange("isPasswordValid", true);
      })
      .catch((error) => {
        setValidationError(error.message);
        onValidationChange("isPasswordValid", false);
      });
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye_icon);
      setType("text");
    } else {
      setIcon(eye_blind_icon);
      setType("password");
    }
  };

  return (
    <div className={styles.userBox}>
      
      <input
        name="password"
        autoComplete="true"
        type={type}
        id={styles.passwordInput}
        className={styles.passwordInput}
        required
        value={value}
        onChange={handleChange}
      ></input>
       <label
        className={styles.label}
        id={styles.passwordInputLabel}
        htmlFor="passwordInput"
        form={form}
        >{labelValue}</label>
      <button className={styles.password_eye} type="button" onClick={handleToggle}>
        <img src={icon} alt="hide/show password" />
      </button>
      {showValidationLabel && validationError && <Label className={styles.errorPasswordInputLabel} id={styles.errorPasswordInputLabel} htmlFor='genericInput' form={form} labelText={validationError}/>}
    </div>
  );
};

export default PasswordInput;