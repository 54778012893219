export interface IUser {
    name: string;
    email: string;
    password: string;
}

export const initialUserState : IUser = {
    name: '',
    email: '',
    password: ''
}

export interface IValidation {
    isUsernameValid:boolean;
    isMailValid:boolean;
    isPasswordValid:boolean;
}

export const initialValidationState : IValidation = {
    isUsernameValid: true,
    isMailValid: false,
    isPasswordValid:false,
}

export type loginUserType = Omit<IUser, 'name' >;

export interface IToken {
    email:string;
    access_token: string;
    refresh_token: string;
}

