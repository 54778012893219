import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import MyProductsModal from "../../MyProductsModal";
import { IProductImage, IResponseProduct } from "../../../constants/IProducts";
import { RoutesEnum } from '../../../constants/routesEnum';
import { useNavigate } from "react-router-dom";

import styles from './ResultStep.module.css';
import iconCameraAuth from '../../../assets/icons/camera_authenticate.svg';
import iconCameraNew from '../../../assets/icons/camera_add.svg';
import iconProduct from '../../../assets/icons/product.svg';



interface IResultStepProps {
    productImages: IProductImage;
    product: IResponseProduct;
    handleMyProductsLoading: (isLoading: boolean) => void;
    resetStep: () => void;
}

const ResultStep = ({ product, productImages, handleMyProductsLoading, resetStep }:IResultStepProps) => {
    const [isMyCsrIdsOpen, setIsMyCsrIdsOpen] = useState<boolean>(false);
    
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    const openMyCsrId = useCallback(() => setIsMyCsrIdsOpen(true), []);
    const closeMyCsrId = useCallback(() => setIsMyCsrIdsOpen(false), []);

    const openAuthCamera = useCallback((selectedPage: 'validate') => {
        navigate(RoutesEnum.CAMERA_PAGE, { state: { cameraPageSelected: selectedPage } });
    }, [navigate]);

    
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Enrolled Product:</h1>
            <div className={styles.resultsContainer}>
                <div className={styles.detailsImageContainer}>
                    { productImages && productImages.base64_image.length > 0  && <img src={`data:image/png;base64, ${productImages.base64_image}`} alt='' className={styles.detailsCsrImage} />}
                </div>
                <div className={styles.resultRow}>
                    <span className={styles.label}>{t('vendorPage.resultStep.productName')}</span>
                    <span className={styles.value}>{product.name || t('vendorPage.na') }</span>
                </div>
                <div className={styles.resultRow}>
                    <span className={styles.label}>{t('vendorPage.resultStep.productDescription')}</span>
                    <span className={styles.value}>{product.description || t('vendorPage.na')}</span>
                </div>
                <div className={styles.resultRow}>
                    <span className={styles.label}>{t('vendorPage.resultStep.productCode')}</span>
                    <span className={styles.value}>{product.product_code || t('vendorPage.na')}</span>
                </div>
                <div className={styles.resultRow}>
                    <span className={styles.label}>{t('vendorPage.resultStep.productLocation')}</span>
                    <span className={styles.value}>
                        {product.production_city && product.production_country 
                            ? `${product.production_city}, ${product.production_country}` 
                            : t('vendorPage.na')
                        }
                    </span>
                </div>
                <div className={styles.resultRow}>
                    <span className={styles.label}>{t('vendorPage.resultStep.csrId')}</span>
                    <span className={styles.value}>{product.csr_code}</span>
                </div>
            </div>
            <button className={`${styles.newButton}`} onClick={openMyCsrId}>
                <img src={iconProduct} alt='' className={styles.icon}/>
                {t('VendorCameraPage.myImages')}
            </button>
            <button className={`${styles.newButton}`} onClick={resetStep}>
                 <img src={iconCameraNew} alt='' className={styles.icon}/>
                 {t('homePage.NewEnrollment')}
            </button>
            <button className={styles.newButton} onClick={()=> openAuthCamera('validate')}>
                <img src={iconCameraAuth} alt='' className={styles.icon}/>
                {t('homePage.validateCsrId')}
            </button>
            {isMyCsrIdsOpen && <MyProductsModal  onClose={closeMyCsrId} handleMyProductsLoading={handleMyProductsLoading}/>}
        </div>
    );
};

export default ResultStep;
