import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RoutesEnum } from '../../constants/routesEnum'

import logo from '../../assets/LogoFull1.png'
import styles from './WelcomePage.module.css'


const WelcomePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = useCallback(() =>  navigate(RoutesEnum.HOME_PAGE),[navigate]);
    
    return (
        <div className={styles.container}>
            <div className={styles.glass}>
                <div className={styles.logoContainer}>
                    <img className={styles.image} alt="IRiSC Logo" src={logo}/>
                </div>
                <div className={styles.textContainer}>
                    <p className={styles.titleWelcome}>{t("welcomePage.csrTitle")}<br/>{t("welcomePage.title")}</p>
                    <p className={styles.subtitleWelcome}>{t("welcomePage.subtitle")}</p>
                </div>
                <div className={styles.buttonContainer}>
                    <button className={styles.newButton} onClick={handleClick}>{t("welcomePage.getStarted")}</button>
                </div>
            </div>
        </div>
    )
}

export default WelcomePage;