import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../store/rootReducer';
import { setEnrolling } from '../../store/slices/userSlice';

import styles from './FaqPage.module.css'


const FaqPage = () => {
    const { t } = useTranslation();
    const dispatch= useAppDispatch();

    useEffect(() => { dispatch(setEnrolling(true))},[dispatch])
    return (
        <div className = {styles.container}>
            <div className={styles.glass}>
                <p className={styles.faqTitle}>{t('faqPage.faqTitle')}</p>
                <p className={styles.faqText}>{t('faqPage.faqText')}</p>
            </div>
        </div>
    )
}

export default FaqPage;