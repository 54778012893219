import  { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {  useDebouncedCallback } from 'use-debounce';

import Searchbar from '../Searchbar';
import { useGetProductsQuery } from '../../store/api/productsApi';
import ProductDetailslModal from './ProductDetailsModal';
import { IResponseProduct, ListRequest, initialRequest, initialResponseProduct } from '../../constants/IProducts';

import arrowLeft from '../../assets/icons/arrow-left.svg';
import arrowRight from '../../assets/icons/arrow_right.svg';
import styles from './MyProductsModal.module.css';

const INPUT_DEBOUNCE = 1000;

interface IMyProductsModal {
    handleMyProductsLoading: (isLoading: boolean) => void;
    onClose: () => void;
}

const MyProductsModal = ({ onClose, handleMyProductsLoading } : IMyProductsModal) => {
    const [page, setPage] = useState<ListRequest>({...initialRequest,});
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [selectedProduct, setSelectedProduct] = useState<IResponseProduct>(initialResponseProduct);
    
    const { data, isLoading, isFetching} = useGetProductsQuery({...initialRequest, page: page.page, txt: query});

    useEffect (() => {
        handleMyProductsLoading(isLoading || isFetching);
    },[handleMyProductsLoading, isFetching, isLoading])

    const openDetailsModal = useCallback((product: IResponseProduct) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
      }, [])
    const closeDetailsModal = useCallback(() => setIsModalOpen(false), []);

    const closeByEsc = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') 
            onClose();
    }, [onClose]);

    useEffect(() => {
        document.addEventListener('keydown', closeByEsc);
        return () => {
            document.removeEventListener('keydown', closeByEsc);
        };
    }, [closeByEsc]);

    const debounced = useDebouncedCallback((value) => setQuery(value),INPUT_DEBOUNCE);

    const onSearchHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        debounced(value);
    },[debounced])

    const handlePrevPage = useCallback(() => {
        if (page.page > 1) {
          setPage((prevPage) => ({ ...prevPage, page: prevPage.page - 1 }));
        }
      },[page.page])
    
      const handleNextPage = useCallback(() => {
        if (data?.page && data.page < data.total_pages) {
          setPage((prevPage) => ({ ...prevPage, page: prevPage.page + 1 }));
        }
    },[data?.page, data?.total_pages]);

    return (
        <div  className={styles.modalOverlay} onClick={onClose} >
           <div className={styles.modalContent} onClick={(e) => e.stopPropagation()} >
                <button className={styles.closeButton} onClick={onClose}>X</button>
                <div className={styles.searchbar}>
                    <Searchbar onSearchHandler={onSearchHandler}/>
                </div>
                <h2 className={styles.h2}>My Products</h2>
                <div className={styles.productList}>
                    {data?.data.map((responseProduct) => (
                        <button key={responseProduct.csr_code} className={styles.productContainer} onClick={() => openDetailsModal(responseProduct)}>
                            <div className={styles.detailsContainer} >
                                <div className={styles.productTextContainer}>
                                    <p className={styles.productLabel}>Product: </p><p className={styles.productName}>{responseProduct.name}</p>
                                </div>
                                <div className={styles.csrCodeContainer}>
                                    <p className={styles.csrCodeLabel}>CaeSaR ID: </p><p className={styles.productCsrCode}>{responseProduct.csr_code}</p>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
                <div className={styles.pagingContainer}>
                    <button className={styles.newButton} disabled={data?.total_pages === 1} onClick={handlePrevPage}>
                        <img src={arrowLeft} alt="Previous" className={styles.icon} />
                    </button>
                    <span className={styles.pageCount}>{data?.page}/{data?.total_pages}</span>
                    <button className={styles.newButton} disabled={data?.total_pages === 1} onClick={handleNextPage}>
                        <img src={arrowRight} alt="Next" className={styles.icon} />
                    </button>
                </div>
                {isModalOpen && <ProductDetailslModal onClose={closeDetailsModal} selectedProduct={selectedProduct}/>}
            </div>
        </div>
    );
    
}

export default MyProductsModal;
