import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { UrlEnum } from '../../constants/url';
import { IProduct, IValidationRequest, IValidationResponse,  IResponseProduct,  ListResponse, ListRequest, IProductImageRequest, IProductImagesResponse, IProductImageResponse } from "../../constants/IProducts";
import { IToken } from "../../constants/IUser";


const baseQuery = fetchBaseQuery({
    baseUrl: window._env_.BE_API_URL,
    prepareHeaders: (headers) => {
        const token = sessionStorage.getItem('access_token');

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set("Content-Type", "application/json");
        }
        return headers;
    },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
    const refreshToken = sessionStorage.getItem('refresh_token');
    if (result.error && result.error.status === 401) {
   
    const refreshResult = await baseQuery({
        url: UrlEnum.USER_URL + UrlEnum.REFRESH_URL, 
        method: 'PUT',
        body: { refresh_token: refreshToken },
        },
        api,
        extraOptions
    );

    if(refreshResult.data) {
        const tmp_data = refreshResult.data as IToken;
        sessionStorage.setItem('access_token',tmp_data.access_token);
        sessionStorage.setItem('refresh_token', tmp_data.refresh_token);
        sessionStorage.setItem('email', tmp_data.email);
        result = await baseQuery(args, api, extraOptions);
    } else {
        sessionStorage.clear();
        toast.warning("Your session has expired. Please log in again.");
        throw new Error("refresh_token_expired");
    }
  }
  return result;
};

export const productsApi = createApi({
    tagTypes: ["productsApi"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getProducts: builder.query<ListResponse<IResponseProduct>,  ListRequest>({
            query: ({page , take, txt}) => {
                const url = txt ? 
                `${UrlEnum.PRODUCTS_URL}?take=${take}&page=${page}&txt=${txt}` : 
                `${UrlEnum.PRODUCTS_URL}?take=${take}&page=${page}`;
                return {
                    url,
                    method: "GET"
                 };
            },
            providesTags: ["productsApi"],
          }),
          getProductsImages: builder.query<IProductImagesResponse,  number>({
            query: (id) => {
                return {
                    url: `${UrlEnum.PRODUCTS_URL}/` + id + UrlEnum.PRODUCT_IMAGES_URL,
                    method: "GET"
                }
            },
          }),
          getProductImage: builder.query<IProductImageResponse,  number>({
            query: (id) => {
                return {
                    url: `${UrlEnum.PRODUCT_IMAGES_URL}/` + id,
                    method: "GET"
                }
            },
          }),
        postProduct: builder.mutation<IResponseProduct, IProduct>({
            query: (product) => ({
                url: UrlEnum.PRODUCTS_URL,
                method: 'POST',
                body:  product  ,
            }),
            invalidatesTags: ["productsApi"],
        }),
        validateImage: builder.mutation<IValidationResponse, IValidationRequest>({
            query: (validationParam) => ({
                url: UrlEnum.PRODUCTS_URL + UrlEnum.VALIDATE_URL,
                method: 'POST',
                body:  {csr_code: validationParam.csr_code, csr_image: validationParam.csr_image, city: validationParam.city, country: validationParam.country} ,
            }),
            invalidatesTags: ["productsApi"],
        }),
        postProductImages: builder.mutation<IProductImagesResponse, IProductImageRequest>({
            query: (productImages) => ({
                url: UrlEnum.PRODUCTS_URL + `/${productImages.id}` + UrlEnum.PRODUCT_IMAGES_URL,
                method: 'POST',
                body:  productImages.productImages.map(imageData => ({
                    base64_image: imageData.base64_image,
                    is_main: imageData.is_main
                })),
            }),
            invalidatesTags: ["productsApi"],
        }),
    }),
});

export const { useGetProductsQuery, useGetProductsImagesQuery, useGetProductImageQuery,  usePostProductMutation, useValidateImageMutation ,usePostProductImagesMutation } = productsApi;
