import {  createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { UrlEnum } from '../../constants/url';
import { ILocationRequest, ILocationResponse } from "../../constants/ILocation";


const baseQuery = fetchBaseQuery({
    baseUrl: window._env_.BE_API_URL,
    prepareHeaders: (headers) => {
        headers.set("Content-Type", "application/json");
        return headers;
    },
})

export const locationApi = createApi({
    reducerPath: 'locationApi', 
    baseQuery: baseQuery,
    endpoints: (builder) => ({
          getLocation: builder.query<ILocationResponse,  ILocationRequest>({
            query: (position) => {
                return {
                    url: `${UrlEnum.GET_LOCATION_URL}?lat=${position.latitude}&lon=${position.longitude}`,
                    method: "GET",
                }
            },
          }),
    }),
});

export const { useGetLocationQuery } = locationApi;
